@use "src/assets/styles/variables";

.container {
  width: 100%;
  padding-left: variables.$content_padding_mobile;

  @media screen and (min-width: variables.$media_l) {
    padding-left: variables.$content_padding_desktop;
  }
}

.title {
  font-size: 2rem !important;

  @media screen and (min-width: variables.$mobile_large) {
    font-size: 2.5rem !important;
  }

  @media screen and (min-width: variables.$media_xs) {
    font-size: 3rem !important;
  }
}

.menoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
  padding: 12px 0;
}

.arrow {
  width: 70px;
  height: 40px;
  display: grid;
}

.horizontalScroll {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 48px;
  padding-bottom: 10px;

  white-space: nowrap;
  background-color: transparent;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
}

.card {
  width: 200px;
  min-width: 200px;
  height: 180px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-radius: 15px !important;
}

.image {
  width: 120px;
  height: 120px;
}
