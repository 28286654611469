@use "src/assets/styles/base";

.container {
  display: grid !important;
  padding: 12px 20px;
  gap: 24px;
  align-items: center;
  border: none;
  width: 100%;
  cursor: pointer;
}

.name {
  justify-self: flex-start;
}

.coloredBackground {
  background-color: #fff;
}

.mobileContainer {
  @extend .container;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.largeMobileContainer {
  @extend .container;
  grid-template-columns: repeat(3, 1fr) 60px;
}

.tabletContainer {
  @extend .container;
  grid-template-columns: repeat(3, 1fr) 60px;
}

.largeTabletContainer {
  @extend .container;
  grid-template-columns: repeat(3, 1fr) 60px;
}

.desktopContainer {
  @extend .container;
  grid-template-columns: 1fr auto auto 60px;
  gap: 40px;
  align-items: center;
}

.rightAlign {
  text-align: right;
  justify-self: flex-end;
}

.icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
}

.icon {
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
}
