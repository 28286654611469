@use "src/assets/styles/animations";
@use "src/assets/styles/variables";
@use "src/assets/styles/base";

.wrapper {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: calc(100vw - 48px);
  max-width: 400px;
  height: 400px;
  border-radius: 15px;
  background-color: #fff;
  display: grid;
  z-index: 20;
  border: 1px solid #e7a59f;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
  grid-template-rows: 30px auto;
  transition: width 0.4s ease, height 0.4s ease;
  @include animations.fade-in-down;

  @media screen and (min-width: variables.$media_hd) {
    max-width: 500px;
    height: 550px;
  }
}

.openIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff !important;
  display: none;
  font-size: 36px !important;
}

.wrapperClosed {
  @extend .wrapper;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #296b6f;
  cursor: pointer;
  transition: none;
  display: grid;
  place-items: center;
  grid-template-rows: none;

  & > .navbar {
    opacity: 0;
    visibility: hidden;
    //display: none;
  }

  & > .container {
    opacity: 0;
    visibility: hidden;
    //display: none;
  }

  & > .openIcon {
    display: block;
  }
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 30px;
  background-color: rgba(231, 165, 159, 0.7);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.iconButton {
  width: 30px;
  height: 30px;
  border-radius: 0;
  padding-left: 12px;
}

.minimize {
  padding-bottom: 6px;
}

.container {
  width: 100%;
  padding-bottom: 2px;
  overflow-y: scroll;
  height: 100%;
  display: grid;
  @extend .scrollbar;
}

.content {
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.inputWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: auto 100px;
  gap: 24px;
}

.inputs {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 12px 6px 12px;
  background-color: #fff;
  border-radius: 15px;
}
