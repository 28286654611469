@use "src/assets/styles/variables";

.container {
  margin: 36px 0;
}

.gridContainer {
  width: 100%;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (min-width: variables.$media_s) {
    grid-template-columns: repeat(auto-fill, 200px);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
}

.icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  display: grid;
  place-items: center;
  margin-bottom: 12px;

  svg {
    width: 60px;
    height: 60px;
  }
}
