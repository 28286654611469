.stateContainer {
  width: 100%;
  height: calc(100% - 100px);
  display: grid;
  place-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.avatar {
  width: 50px;
  height: 50px;
}

.goBack {
  display: flex;
  flex-direction: row;
  align-items: center;
}
