@use "src/assets/styles/variables";
@use "src/assets/styles/base";

.form {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

  @media screen and (min-width: variables.$media_l) {
    padding: 48px;
  }
}

.link {
  color: #000;
  margin-left: 6px;
}

.extraInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 14px;
}

.getStartedTitle {
  margin-top: 8px;
}

.link {
  text-decoration: none;
}

.input {
  fieldset {
    border: 1px solid variables.$red !important;
  }
}

.socialContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 24px;

  button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    background: #ffffff;
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.2);
    border-color: transparent;
    cursor: pointer;
  }
}

.icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.buttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
}

.verifyContainer {
  width: 100%;
  padding: 24px;
  display: grid;
  place-items: center;
}

.verifyContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
