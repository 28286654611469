@use "src/assets/styles/variables";

.container {
  padding-top: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
