.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96%;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: scroll;

  @media screen and (min-width: 1024px) {
    min-width: 500px;
    max-width: 900px;
  }
}

.close {
  width: 40px !important;
  height: 40px !important;
  position: absolute !important;
  top: 1rem;
  right: 1rem;
}

.icon {
  width: 30px;
  height: 30px;
}
