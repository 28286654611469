@use "src/assets/styles/variables";
@use "src/assets/styles/animations";

.wrapper {
  width: 100%;
}

.container {
  border-radius: 15px !important;
  padding: 12px !important;
  width: 100%;
  height: 700px;
  position: relative;
  @include animations.fade-in-down(0.3s, 0.1s);

  @media screen and (min-width: variables.$media_m) {
    height: 450px;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (min-width: variables.$media_l) {
    height: 450px;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (min-width: variables.$media_xxl) {
    height: 500px;
  }
}

.image {
  width: 100%;
  border-radius: 10px !important;

  @media screen and (min-width: variables.$media_l) {
    width: 70%;
  }
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  hyphens: auto !important;
}

.textContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 12px 0;
  line-height: 140%;
}

.content {
  overflow: hidden;
}

.bottomBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 10;
}
