@use "src/assets/styles/variables";

.header {
  width: 100%;
  margin-bottom: 24px;
}

.mainRow {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: variables.$media_s) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.title {
  @media screen and (min-width: variables.$media_m) {
    margin-bottom: 12px !important;
  }
}
