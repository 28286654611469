@use "src/assets/styles/variables";

.input {
  width: 100% !important;
  min-width: unset;
  max-width: unset;
  background-color: rgba(231, 165, 159, 0.2) !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  padding: 2px 16px 2px 4px !important;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (min-width: variables.$mobile_large) {
    width: 80% !important;
  }

  @media screen and (min-width: variables.$media_s) {
    min-width: 350px;
    max-width: 500px;
  }

  @media screen and (min-width: variables.$media_l) {
    min-width: 350px;
    max-width: 500px;
  }
}

.searchIcon {
  width: 22px;
  height: 22px;
  margin-left: 4px;
  margin-top: 4px;

  @media screen and (min-width: variables.$media_m) {
    width: 30px;
    height: 30px;
  }
}

.innerInput {
  width: 100%;
  font-size: 16px !important;
  font-weight: 300 !important;
  margin-left: 8px;
  padding-right: 16px;

  @media screen and (min-width: variables.$media_m) {
    font-size: 18px !important;
  }
}
