$mobile_large: 360px;
$media_xs: 420px;
$media_s: 576px;
$media_m: 768px;
$media_l: 1024px;
$media_xl: 1280px;
$media_xxl: 1400px;
$media_hd: 1600px;

$sidebar_width: 300px;
$grid_gap: 26px;

$content_padding_mobile: 24px;
$content_padding_desktop: 36px;

$green: #3d6d71;
$red: #b5234d;
$orange: #d1684f;
