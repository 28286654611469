@use "src/assets/styles/variables";

.wrapper {
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: variables.$content_padding_mobile;

  //&::-webkit-scrollbar {
  //  width: 4px;
  //  height: 4px;
  //}

  @media screen and (min-width: variables.$media_l) {
    height: calc(100vh - 24px);
    padding: variables.$content_padding_desktop;
  }
}

.wrapperWithSearch {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 100px auto;
  padding-top: 24px;
  background: linear-gradient(0deg, rgba(231, 165, 159, 0.4) 0%, rgba(231, 165, 159, 0) 100%);

  @media screen and (min-width: variables.$mobile_large) {
    grid-template-rows: 75px auto;
  }

  @media screen and (min-width: variables.$media_l) {
    padding-top: 0;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }
}

.containerWithSearch {
  @extend .container;
  height: 100%;
  border-radius: 15px;

  @media screen and (min-width: variables.$media_l) {
    height: calc(100vh - 99px); // padding + grid + search bar height
  }
}
