@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
}

.navigationContainer {
  @extend .container;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    padding: 12px;
    grid-template-columns: variables.$sidebar_width auto;
  }
}
