@use "src/assets/styles/variables";

.content {
  background-color: #fff;
  border-radius: 14px;
  padding: 8px 12px;

  @media screen and (min-width: variables.$media_l) {
    padding: 8px 24px;
  }
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
