.wrapper {
  width: 100%;
}

.innerWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
}
