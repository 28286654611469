@use "src/assets/styles/base";
@use "src/assets/styles/variables";

.content {
  padding: 24px 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media screen and (min-width: variables.$media_xxl) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (min-width: variables.$media_s) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  margin-left: -32px;

  @media screen and (min-width: variables.$media_s) {
    margin-bottom: 0;
    margin-left: 0;
  }
}

.emptyStateWrapper {
  @include base.full-height(variables.$media_m, 90px, 90px);
}
