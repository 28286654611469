@use "src/assets/styles/variables";

.stateContainer {
  width: 100%;
  height: calc(100% - 100px);
  display: grid;
  place-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (min-width: variables.$media_s) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
  margin-left: -32px;

  @media screen and (min-width: variables.$media_s) {
    margin-bottom: 0;
    margin-left: 0;
  }
}

.buttonRow {
  @extend .row;
  margin-left: 0;

  flex-direction: column;
  @media screen and (min-width: variables.$media_m) {
    flex-direction: row;
  }
}
