@use "src/assets/styles/variables";

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  gap: 12px;
}
