@use "src/assets/styles/variables";

.container {
  width: 100%;
  margin-top: 32px;
  min-height: 300px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.replyItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  border: 1px solid #c8c8c8;
  border-radius: 8px;
  padding: 16px;

  @media screen and (min-width: variables.$media_s) {
    display: grid;
    grid-template-columns: 60px auto auto;
  }
}

.date {
  justify-self: flex-end;
}

.avatar {
  width: 60px;
  height: 60px;
}

.inputOuterContainer {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (min-width: variables.$media_s) {
    flex-direction: row;
    gap: 24px;
    align-items: flex-end;
  }
}

.input {
  background-color: #fff;
}
