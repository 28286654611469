.container {
  width: 100%;
  display: grid;
  grid-template-columns: 50px auto;
  flex-direction: row;
  gap: 16px;
  align-items: flex-end;
  position: relative;
}

.containerReverse {
  @extend .container;
  grid-template-columns: auto 50px;
}

.messageWrapper {
  padding: 8px;
  background: #d9d9d9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.avatar {
  width: 46px !important;
  height: 46px !important;
  background-color: #e7a59f;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.logo {
  width: 33px;
  height: 22px;
}
