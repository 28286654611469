@use "src/assets/styles/variables";

.container {
  margin: 48px 0;
}

.gridContainer {
  width: 100%;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 156px;
  gap: 12px;

  @media screen and (min-width: variables.$media_s) {
    grid-template-columns: repeat(auto-fill, 200px);
  }
}

.color {
  text-align: center;
  font-size: 18px;
  align-self: center;
}

.loader {
  width: 100%;
  height: 156px;
  display: grid;
  place-items: center;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f3f3f3;
    filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.button {
  height: 120px;
  align-self: center;
  color: #fff !important;
}
