@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: variables.$media_l) {
    justify-content: space-between;
    padding-bottom: 64px;
    padding-top: 36px;
  }
}

.mainContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: variables.$media_m) {
    flex-direction: row;
  }

  @media screen and (min-width: variables.$media_l) {
    flex-direction: column;
  }

  @media screen and (min-width: variables.$media_xl) {
    flex-direction: row;
    align-items: center;
    gap: 64px;
    padding-right: 64px;
  }
}

.details {
  padding-bottom: 48px;
  align-self: flex-start;
}

.name {
  font-size: 24px !important;

  @media screen and (min-width: variables.$media_s) {
    font-size: 34px !important;
  }
}

.image {
  width: 100%;
  margin-bottom: 24px;
  border-radius: 15px;

  @media screen and (min-width: variables.$media_s) {
    width: 50%;
  }

  @media screen and (min-width: variables.$media_l) {
    max-width: 400px;
  }
}

.textInput {
  width: 100%;
}

.text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
