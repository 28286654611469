.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding-top: 48px;

  @media screen and (min-width: 768px) {
    max-width: 740px;
    box-sizing: content-box;
  }
}
