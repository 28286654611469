@use "src/assets/styles/base";
@use "src/assets/styles/variables";

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 320fr));
  gap: 24px;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (min-width: variables.$media_m) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  @media screen and (min-width: variables.$media_m) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.emptyStateWrapper {
  @include base.full-height(variables.$media_m, 177px, 81px);
}

.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
