.container {
  padding: 24px;
  width: 100%;
  height: 100vh;
}

.form {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.formElements {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
