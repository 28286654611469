.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0;
}

.termsLink {
  color: inherit;
  margin-left: 0;
  text-align: left;
}
