@use "src/assets/styles/variables";

.box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 0;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 10;

  @media all and (min-width: variables.$media_l) {
    position: relative;
    transform: translateX(0);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
}

.closeIcon {
  position: absolute !important;
  top: 1.9rem !important;
  right: 1rem !important;
  display: block !important;

  @media all and (min-width: variables.$media_l) {
    display: none !important;
  }
}

.boxOpened {
  @extend .box;
  transform: translateX(0);
}

.item {
  margin-left: 10px;
}

.active {
  div {
    color: #000 !important;
  }
}

.logoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 54px;
}

.logo {
  margin: auto;
  justify-self: center;
}

.logoutContainer {
  width: 100%;
  position: absolute;
  bottom: 16px;
  left: 0;
  background-color: #fff;
}

.sidebarContainer {
  height: 76vh;
  overflow-y: scroll;

  @media screen and (min-width: variables.$media_l) {
    height: 70vh;
  }
}
