@use "src/assets/styles/animations";

.container {
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  @include animations.fade-in;
}
