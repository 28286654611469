@use "src/assets/styles/variables";
@use "src/assets/styles/animations";

.container {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 24px;
  border-radius: 15px !important;
  height: 700px;
  @include animations.fade-in-down;

  @media screen and (min-width: variables.$media_s) {
    gap: 12px;
    height: 450px;
  }

  @media screen and (min-width: variables.$media_l) {
    height: 450px;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (min-width: variables.$media_xxl) {
    height: 500px;
  }
}

.textContainer {
  width: 100%;
  overflow: hidden;
  margin-bottom: 12px;
  line-height: 140%;
  padding-top: 12px;
  position: relative;

  p {
    margin: 6px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }

  @media screen and (min-width: variables.$media_s) {
    padding-top: 0;
  }
}

.title {
  hyphens: auto !important;

  @media screen and (min-width: variables.$media_l) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media screen and (min-width: variables.$media_s) {
    flex-direction: row;
    gap: 12px;
  }

  @media screen and (min-width: 880px) {
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  @media screen and (min-width: variables.$media_l) {
    flex-direction: row;
    gap: 24px;
  }
}

.reverseContent {
  @extend .content;

  @media screen and (min-width: variables.$media_l) {
    flex-direction: row-reverse;
  }
}

.image {
  max-width: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: right;

  @media screen and (min-width: variables.$media_s) {
    max-height: 300px;
  }

  @media screen and (min-width: variables.$media_m) {
    max-width: 60%;
  }
}

.imageLeftAlign {
  @extend .image;
  object-position: left;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px;
  margin-bottom: 24px;

  @media screen and (min-width: variables.$media_s) {
    margin-top: 0;
    gap: 24px;
    margin-bottom: 0;
    flex-direction: row;
    align-items: center;
  }
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
}

.lookOnBlog {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 100%
  );
}

.bottomBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 10;
}
