@use "src/assets/styles/variables";

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
  position: relative;
}

.removeButton {
  position: absolute !important;
  top: -20px;
  left: -20px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: #fff !important;
  border: 1px solid #e7a59f !important;
}

.image {
  width: 60px;
  height: 60px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
