.container {
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
  }
}

.label {
  font-weight: 600;
  margin-top: 24px;
  font-size: 18px;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}
