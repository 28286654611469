.wrapper {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.data {
  width: 100%;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: 200px auto;
}
