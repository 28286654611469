.container {
  width: 100%;
  max-height: 360px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-rows: 50% 50%;
  background-color: #fff;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
