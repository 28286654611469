@use "src/assets/styles/variables";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: variables.$media_l) {
    padding: variables.$content_padding_desktop;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
}

.sideContainer {
  display: flex;
  flex-direction: column;
  gap: variables.$grid_gap;
  margin-top: 24px;

  @media screen and (min-width: variables.$media_l) {
    margin-top: 0;
  }
}

.wrapper {
  width: 100%;
  height: auto;
  background-color: #fff !important;
  box-shadow: 0 5.10701px 3.83026px rgba(0, 0, 0, 0.2);
  border-radius: 15px !important;
  padding: 24px !important;
}

.button {
  @extend .wrapper;
  border: none;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start !important;
  gap: 24px;
}

.profile {
  @extend .wrapper;
  height: 100%;
}

.icon {
  width: 40px;
  height: 40px;
}
