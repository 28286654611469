@use "src/assets/styles/animations";

.dayWrapper {
  position: relative;
  justify-self: center;
}

.iconWrapper {
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  svg {
    width: 16px;
    height: 16px;
    @include animations.fade-in(0.3s);
  }
}

.onlyCompletedWrapper {
  @extend .iconWrapper;
  bottom: -18px;
}

.completedDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #296b6f;
  @include animations.fade-in(0.3s);
}
