.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 24px;
}

.icon {
  width: 30px;
  height: 30px;
  stroke: #284a5d;
  stroke-width: 1px;
}

.fb {
  fill: #284a5d;
}

.link {
  color: #284b5e;
  font-weight: 600;
}
