.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
}

.icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  display: grid;
  place-items: center;
  margin-bottom: 12px;
  transition: 0.4s ease;

  svg {
    width: 50px;
    height: 50px;
    color: #000;
    transition: color 0.2s ease;
  }
}

.selectedIcon {
  @extend .icon;
  border: 6px solid #dea8a1;
}

.iconButton {
  overflow: hidden;
  position: relative;
}

.imageIcon {
  width: 100%;
  height: 100%;
  transform: scale(1.1);
}
