.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 12px 24px;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000000;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  transform: translateY(0);
  transition: 0.2s ease;
}

.hidden {
  @extend .container;
  transform: translateY(110%);
}

.text {
  font-size: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
