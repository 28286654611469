.container {
  width: 100%;
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 12px;
  padding: 12px 24px;
}
