@use "src/assets/styles/variables";

.wrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 24px;
  margin-top: 24px;
  position: relative;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: 12px;
  right: 24px;

  @media screen and (min-width: variables.$media_m) {
    top: 24px;
    right: 24px;
    gap: 24px;
  }
}
