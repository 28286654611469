@use "src/assets/styles/base";
@use "src/assets/styles/variables";

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.select {
  width: 150px !important;

  @media screen and (min-width: variables.$media_s) {
    width: 170px !important;
  }
}

.emptyStateWrapper {
  @include base.full-height(variables.$media_s, 180px, 150px);
}
