@use "src/assets/styles/variables";
@use "src/assets/styles/animations";

.container {
  padding: variables.$content_padding_mobile;
  @include animations.fade-in-down;

  @media screen and (min-width: variables.$media_l) {
    padding: variables.$content_padding_desktop;
  }
}

.title {
  font-size: 40px !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 64px !important;
  }
}

.content {
  width: 100%;
}

.image {
  float: right;
  width: 100%;
  border-radius: 10px;
  margin: 24px 0 24px 12px;

  @media screen and (min-width: variables.$media_l) {
    margin-left: 80px;
    width: 40%;
  }
}

.text {
  white-space: pre-line;

  @media screen and (min-width: variables.$media_l) {
    margin-top: 24px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
}
