@use "src/assets/styles/base";
@use "src/assets/styles/variables";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: variables.$grid_gap;
}

.emptyStateWrapper {
  @include base.full-height(variables.$media_m, 90px, 90px);
}
