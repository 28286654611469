.title {
  font-size: 16px !important;
  color: #000;
  font-weight: 600 !important;
  padding: 12px 0 12px 12px;
}

.sectionParagraph {
  font-size: 14px;
  color: #ccc;
  padding: 12px 24px 12px 24px;
}

.sectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.link {
  display: block;
  width: 100%;
  color: #000;
  padding: 8px 24px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }
}

.innerLink {
  @extend .link;
  padding-left: 40px;
}

.border {
  width: 100%;
  padding-bottom: 12px;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
