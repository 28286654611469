@use "src/assets/styles/animations";

.form {
  width: 100%;
  height: 100%;
  @include animations.fade-in;
}

.input {
  border-radius: 8px;
  padding: 12px;
  border: 1px solid;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 800px;
  margin-top: 36px;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
