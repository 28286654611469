.container {
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 60px auto;
  padding: 16px;
  border-radius: 8px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.avatar {
  width: 40px !important;
  height: 40px !important;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 3 !important;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.title {
  @extend .text;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
