@use "src/assets/styles/variables";

.content {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: variables.$media_s) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 880px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: variables.$media_xxl) {
  }
}

.mainArticle {
  @media screen and (min-width: variables.$media_xs) {
    grid-column: 1/3;
  }

  @media screen and (min-width: 880px) {
    grid-column: 1/3;
  }

  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/5;
  }

  @media screen and (min-width: variables.$media_xxl) {
    grid-column: 1/4;
  }
}

.secondArticle {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/3;
  }

  @media screen and (min-width: variables.$media_xxl) {
    grid-column: 4/5;
  }
}

.thirdArticle {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 3/5;
  }

  @media screen and (min-width: variables.$media_xxl) {
    grid-column: 1/2;
  }
}

.fourthArticle {
  @media screen and (min-width: variables.$media_s) {
    grid-column: 1/3;
  }

  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/5;
  }

  @media screen and (min-width: variables.$media_xxl) {
    grid-column: 2/5;
  }
}

.fifthArticle {
  @media screen and (min-width: variables.$media_s) {
    grid-column: 1/3;
  }

  @media screen and (min-width: 880px) {
    grid-column: 3/5;
  }

  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/4;
  }
}

.sixthArticle {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 4/5;
  }
}

.seventhArticle {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 1/3;
  }

  @media screen and (min-width: variables.$media_xxl) {
    grid-column: 1/2;
  }
}

.eightArticle {
  @media screen and (min-width: variables.$media_l) {
    grid-column: 3/5;
  }

  @media screen and (min-width: variables.$media_xxl) {
    grid-column: 2/3;
  }
}

.secondaryArticle {
  @media screen and (min-width: variables.$media_xs) {
    grid-column: 1/4;
  }

  @media screen and (min-width: 880px) {
    grid-column: 2/5;
    grid-row: 2/3;
  }

  @media screen and (min-width: variables.$media_xxl) {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.image {
  height: 100px;
}

.titleImage {
  height: 60%;
}
