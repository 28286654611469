@use "src/assets/styles/variables";
@use "src/assets/styles/base";
@use "src/assets/styles/animations";

.results {
  position: absolute;
  width: calc(100vw - 48px);
  top: 110%;
  left: 0;
  max-height: 300px;
  padding: 24px 0;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid variables.$green;
  z-index: 20;
  @extend .scrollbar;
  @include animations.fade-in(0.2s, 0);

  @media screen and (min-width: variables.$media_s) {
    width: 100%;
  }
}
