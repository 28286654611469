.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 30px;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
}

.weekDay {
  text-align: center;
  font-weight: bold;
}

.navigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.monthSelect {
  width: 200px;
}

.yearSelect {
  width: 110px;
}
