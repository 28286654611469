@use "src/assets/styles/variables";
@use "src/assets/styles/animations";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include animations.fade-in;

  @media screen and (min-width: variables.$media_m) {
    display: grid;
    grid-template-columns: 70% 30%;
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  margin-bottom: 48px;
}
