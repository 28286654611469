@use "variables";

.button {
  border: none;
  border-radius: 30px !important;
  padding: 8px 24px !important;
  width: auto;
  font-size: 14px !important;
  max-width: 220px;
  background-color: variables.$red !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-family: Montserrat, sans-serif !important;

  &:disabled {
    opacity: 0.6;
    cursor: auto;
  }

  &:hover {
    background-color: variables.$red !important;
  }
}

.tag {
  color: variables.$red !important;
  font-size: 34px !important;
  font-family: Montserrat, sans-serif !important;
  font-weight: 500 !important;
  margin-top: 8px;
}

.tag span {
  color: variables.$orange !important;
}

.title {
  color: variables.$green;
  font-size: 40px !important;
  font-weight: 600;
  font-family: "Merriweather Sans", sans-serif !important;
  font-style: italic;
}

.scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(231, 165, 159, 1);
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

@mixin full-height($breakpoint: variables.$media_m, $heightMobile: 150px, $heightDesktop: 150px) {
  width: 100%;
  height: calc(100% - $heightMobile);
  display: grid;
  place-items: center;

  @media screen and (min-width: $breakpoint) {
    height: calc(100% - $heightDesktop);
  }
}
