@use "src/assets/styles/animations";

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: calc(100% - 60px) 60px;
  row-gap: 16px;
  @include animations.fade-in;
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

.buttons {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
