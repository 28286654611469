@use "src/assets/styles/variables";
@use "src/assets/styles/base";

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
  background-color: #c5dfe5;

  @media screen and (min-width: variables.$media_l) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.logo {
  position: absolute;
  top: 24px;
  left: 24px;
  //width: 240px;

  @media screen and (min-width: variables.$media_l) {
    //width: 400px;
    //transform: scale(1.5);
  }
}

.sidebar {
  width: 100%;
  height: 100%;
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: flex;
    flex-direction: row;
    background-color: #d5dbde;
    padding: 0 48px;
  }
}

.imageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  width: 80%;
  position: relative;
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.imageButton {
  @extend .button;
  position: absolute !important;
  top: 50% !important;
  right: 20% !important;

  @media screen and (min-width: variables.$media_l) {
    font-size: 12px !important;
  }

  @media screen and (min-width: variables.$media_xl) {
    font-size: 14px !important;
  }
}
