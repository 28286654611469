@use "src/assets/styles/animations";

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.form {
  width: 100%;
  height: 100%;
  @include animations.fade-in;
}
