@use "src/assets/styles/variables";

.container {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 variables.$content_padding_mobile;

  @media screen and (min-width: variables.$media_l) {
    padding: 0 variables.$content_padding_desktop;
  }
}

.searchContainer {
  @extend .container;
  flex-direction: column-reverse;
  gap: 12px;

  @media screen and (min-width: variables.$mobile_large) {
    gap: 0;
    flex-direction: row;
    justify-content: space-between;
  }
}

.icons {
  width: 80px;
  display: flex;
  gap: 3px;
  justify-content: flex-end;
  align-self: flex-end;

  @media screen and (min-width: variables.$mobile_large) {
    align-self: unset;
  }

  @media screen and (min-width: variables.$media_m) {
    gap: 10px;
  }
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.cartIcon {
  position: relative;
}

.cartQuantity {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  font-size: 10px;
  background-color: #e7a59f;
  color: #fff;
  position: absolute;
  bottom: 4px;
  right: 4px;
  display: grid;
  place-items: center;
}

.hamburger {
  display: block !important;

  @media screen and (min-width: variables.$media_l) {
    display: none !important;
  }
}
