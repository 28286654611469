.container {
  width: 100%;
  height: calc(100vh - 80px) !important;
  font-weight: 800;
  display: grid;
  place-items: center;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.container img {
  width: 150px;
  margin-bottom: 20px;
}

.container button {
  max-width: 80vw;
  white-space: nowrap;
}

.big button {
  font-size: 13px;
  height: 38px;
  margin-top: 34px;
  margin-bottom: 50px;
  width: 150px;
}

.medium button {
  margin-top: 15px;
  width: 150px;
}

.small button {
  margin-top: 15px;
  width: 100px;
}
