@use "src/assets/styles/variables";

.productsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;

  @media screen and (min-width: variables.$media_m) {
    display: grid;
    grid-template-columns: 70% 30%;
  }
}
