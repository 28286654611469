.todayBorder {
  outline: 2px solid #62d4f7 !important;
}

.selectedBorder {
  outline: 2px solid #4eaa78 !important;
}

.completedDot {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #296b6f;
  }
}
