@use "src/assets/styles/variables";

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (min-width: variables.$media_m) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
