@use "src/assets/styles/variables";
@use "src/assets/styles/animations";

.wrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 24px;
  @include animations.fade-in;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.weekOfYear {
  display: none;

  @media screen and (min-width: variables.$media_l) {
    display: block;
  }
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
