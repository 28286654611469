@use "src/assets/styles/variables";

.container {
  padding: 24px 0 !important;
}

.news {
  padding: variables.$content_padding_mobile;

  @media screen and (min-width: variables.$media_l) {
    padding: variables.$content_padding_desktop;
  }
}
