@use "src/assets/styles/variables";
@use "src/assets/styles/base";

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
  padding: 24px;

  @media screen and (min-width: variables.$media_l) {
    padding: 48px;
  }
}

.getStartedTitle {
  margin-top: 8px;
}

.link {
  color: #000;
  margin-left: 6px;
  text-decoration: none;
}

.input {
  fieldset {
    border: 1px solid variables.$red !important;
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
}
